import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'theme/libs/styled';

const Wrapper = styled('div', {
    shouldForwardProp: prop => ['timingFunction', 'duration', 'showHeight'].indexOf(prop) === -1,
})`
    max-height: ${({ showHeight }) => showHeight && showHeight};
    overflow: hidden;
    transition: ${({ duration, timingFunction }) => duration > 0 && `max-height ${duration}ms ${timingFunction}`};
`;

class AnimateHeight extends Component {
    element = createRef();
    lastMaxHeight = 0;

    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        // Transition duration in milliseconds.
        duration: PropTypes.number,
        id: PropTypes.string,
        isFull: PropTypes.bool,
        showHeight: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        timingFunction: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        className: null,
        duration: 200,
        id: null,
        isFull: false,
        showHeight: 0,
        timingFunction: 'cubic-bezier(0.46, 0.88, 0.7, 0.83)',
    };

    componentDidMount() {
        this.updateHeight(this.props, this.element.current);
    }

    componentDidUpdate(prevProps) {
        const { isFull } = this.props;
        const node = this.element.current;

        if (prevProps.isFull !== isFull && this.lastMaxHeight !== node.scrollHeight) {
            this.updateHeight(this.props, node);
        }
    }

    // @todo: Find a way to animate when maxHeight shrink
    updateHeight(props, node) {
        const { isFull, maxHeight } = props;
        if (node) {
            const scrollHeight = node.scrollHeight;
            const height = maxHeight
                ? scrollHeight > parseInt(maxHeight, 10)
                    ? maxHeight
                    : scrollHeight
                : scrollHeight;
            const value = isFull ? (isNaN(height) ? height : `${height}px`) : null;

            node.style.maxHeight = value;
            this.lastMaxHeight = parseInt(height, 100);
        }
    }

    render() {
        const { children, duration, timingFunction, className, id, showHeight } = this.props;

        return (
            <Wrapper
                id={id}
                ref={this.element}
                duration={duration}
                timingFunction={timingFunction}
                className={className}
                showHeight={showHeight}
            >
                {children}
            </Wrapper>
        );
    }
}

export default AnimateHeight;
