import React from 'react';
import PropTypes from 'prop-types';
import Link from 'core-web/components/Link';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';
import H1 from 'theme/components/base/headings/H1';
import H2 from 'theme/components/base/headings/H2';
import RoundedButton from 'theme/components/buttons/RoundedButton';

const Div = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const LinkCard = ({ link, heading, isH1Heading }) => {
    const { text: linkText, url: linkUrl } = link || {};
    return (
        <Div p={['16px', null, '32px']}>
            {heading ? (
                isH1Heading ? (
                    <H1
                        color={colors.black}
                        maxWidth={['400px', '500px', '550px']}
                        lineHeight="30px"
                        fontSize="24px"
                        zIndex="10"
                        mb={['24px', null, '32px']}
                        mr="0px"
                    >
                        {heading}
                    </H1>
                ) : (
                    <H2
                        textAlign={['center', null, 'left']}
                        color={colors.black}
                        maxWidth={['400px', '500px', '550px']}
                        lineHeight="30px"
                        fontSize="24px"
                        zIndex="10"
                        mb={['24px', null, '32px']}
                        mr="0px"
                    >
                        {heading}
                    </H2>
                )
            ) : null}
            {linkUrl && linkText && (
                <RoundedButton
                    as={Link}
                    to={linkUrl}
                    theme="green"
                    fontSize={['12px', null, '14px']}
                    height="fit-content"
                    padding="16px"
                    whiteSpace="nowrap"
                    ml="0px"
                >
                    {linkText}
                </RoundedButton>
            )}
        </Div>
    );
};

LinkCard.propTypes = {
    heading: PropTypes.string,
    isH1Heading: PropTypes.bool.isRequired,
    link: PropTypes.object,
};

LinkCard.defaultProps = {
    heading: '',
    link: '',
};

export default LinkCard;
