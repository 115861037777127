import React from 'react';
import PropTypes from 'prop-types';
import styled from 'theme/libs/styled';
import FilterInput from 'theme/components/productguide/FilterInput';
import { above } from 'core-web/util/mediaqueries';

const Div = styled('div')`
    & > *:not(:first-of-type) {
        margin-left: 8px;
        ${above.lg} {
            margin-left: 16px;
        }
    }
`;

const FilterInputs = ({ filter, callback, keyValues, handleEnter }) => (
    <Div display="flex" width="100%">
        {filter.map((props, index) => (
            <FilterInput
                {...props}
                key={index + props.type}
                id={index}
                keyValues={keyValues}
                callback={callback}
                handleEnter={handleEnter}
            />
        ))}
    </Div>
);

FilterInputs.propTypes = {
    callback: PropTypes.func,
    filter: PropTypes.array.isRequired,
    handleEnter: PropTypes.func,
    keyValues: PropTypes.array,
    type: PropTypes.string,
};

FilterInputs.defaultProps = {
    callback: null,
    handleEnter: null,
    keyValues: [],
    type: '',
};

export default FilterInputs;
